import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Tab from "./Tab";
import data from "./experiences.json";

import js from "../../assets/Experiences/js.png";
import ih from "../../assets/Experiences/ih.png";
import kiavi from "../../assets/Experiences/kiavi.png";
import relay from "../../assets/Experiences/relay.png";
import perkup from "../../assets/Experiences/perkup.png";
import waabi from "../../assets/Experiences/waabiW.png";

const keyToIcon = {
  0: js,
  1: ih,
  2: waabi,
  3: kiavi,
  4: relay,
  5: perkup,
};

const Experience = () => {
  return (
    <div className="container homepage-scroll-section-dark mt-n5 mt-md-0">
      <div className="text-center">
        <h3 className="section-message">
          I've had the chance to impact some amazing companies
        </h3>
        <h2 className="section-title">Experience</h2>
      </div>

      <VerticalTimeline lineColor="#000814">
        {data.experiences.map((experience, index) => (
          <VerticalTimelineElement
            onTimelineElementClick={() => {
              window.open(experience.url, "_blank");
            }}
            textClassName={
              index % 2 !== 0 ? "core-card-blue-shadow" : "core-card-red-shadow"
            }
            date={experience.date}
            dateClassName="experience-date"
            iconStyle={{ background: "#fff", borderColor: "#000" }}
            icon={
              <img src={keyToIcon[index]} alt="Logo" className="footer-logo" />
            }
          >
            <Tab experience={experience} />
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
