import React from "react";
import "../HomePage/HomePage.css";
import portrait from "../../assets/HomePage/portrait.png";
import { FaCircle } from "react-icons/fa";

const AboutMe = () => {
  return (
    <div className="container homepage-scroll-section-dark mt-n5 mt-md-0">
      <div className="text-center">
        <h3 className="section-message">Here's a bit about me</h3>
        <h2 className="section-title">About Me</h2>
      </div>
      <div className="row justify-content-center justify-content-md-between align-items-center">
        <div className="col-md-6 text-left">
          <div className="card">
            <div className="card-header p-1 pl-2">
              <FaCircle className="about-me-icon" color="#ff5f57" />
              <FaCircle className="about-me-icon" color="#febc2d" />
              <FaCircle className="about-me-icon" color="#28c840" />
            </div>
            <div
              className="card-body ml-1 mr-1"
              style={{
                lineHeight: "200%",
              }}
            >
              <div className="about-me-description-header">
                <span role="img" aria-label="emoji name">
                  👋
                </span>{" "}
                Hey, I'm Sharan!
              </div>
              <div className="about-me-description-text">
                <div className="about-me-description-text">
                  <span role="img" aria-label="emoji name">
                    🎓
                  </span>{" "}
                  Hacking my way through a
                  <a
                    className="about-me-link"
                    href="https://uwaterloo.ca/future-students/programs/computer-science"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Computer Science degree
                  </a>{" "}
                  at the{" "}
                  <a
                    className="about-me-link"
                    href="https://cs.uwaterloo.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    University of Waterloo
                  </a>
                  .
                </div>
              </div>
              <div className="about-me-description-text">
                <span role="img" aria-label="emoji name">
                  💻
                </span>{" "}
                Figuring out what I enjoy by working through a <em>few</em> internships.
              </div>
              <div className="about-me-description-text">
                <span role="img" aria-label="emoji name">
                  ☕️
                </span>{" "}
                Impressed or just mildly amused? Either way, let's grab a coffee
                and chat!
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-8 mt-5 mt-md-0">
          <img src={portrait} alt="portrait" className="col" />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
