import {
  Heading,
  Pane,
  SideSheet,
  Tablist,
  Tab,
  Position,
  Badge,
  Text,
} from "evergreen-ui";
import React, { useState, useEffect, useMemo } from "react";

import createImg from "../../assets/Projects/create.png";
import manageImg from "../../assets/Projects/manage.png";
import interviewImg from "../../assets/Projects/interview.png";

const AMSidesheet = ({ isShown, setIsShown }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [loadedImages, setLoadedImages] = useState(false);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const pictures = useMemo(() => [createImg, manageImg, interviewImg], []);

  useEffect(() => {
    if (isShown && !loadedImages) {
      console.log("in here");
      for (const picture of pictures) {
        const img = new Image();
        img.src = picture;
      }
      setLoadedImages(true);
    }
  }, [isShown, loadedImages, pictures]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const STACK = [
    "JavaScript",
    "React",
    "NodeJS",
    "Express",
    "MySQL",
    "SQL",
    "AWS",
  ];

  const FEATURES = [
    {
      title: "Create Posts",
      img: createImg,
      description:
        "Allow researchers to create detailed posts for their research opportunity.",
    },
    {
      title: "Manage Offers",
      img: manageImg,
      description:
        "Provide researchers a view of all applicant information and the ability to enforce decisions.",
    },
    {
      title: "Schedule Interviews",
      img: interviewImg,
      description:
        "Provide researchers and students a process to schedule interviews at an agreeable time.",
    },
  ];
  return (
    <SideSheet
      position={isMobile ? Position.BOTTOM : Position.RIGHT}
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      containerProps={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
      }}
    >
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16} borderBottom="muted">
          <Heading marginBottom={4} size={600}>
            Here's some of my work @AcademiaMatch
          </Heading>
          {STACK.map((item, index) => (
            <Badge marginRight={4} key={index} color="blue">
              {item}
            </Badge>
          ))}
        </Pane>
        <Pane display="flex" padding={8}>
          <Tablist>
            {FEATURES.map((feature, index) => (
              <Tab
                key={feature.title}
                isSelected={selectedIndex === index}
                onSelect={() => setSelectedIndex(index)}
              >
                {feature.title}
              </Tab>
            ))}
          </Tablist>
        </Pane>
      </Pane>
      <Pane flex={1} background="tint1">
        <Pane textAlign="center" padding={16}>
          <Pane marginBottom={8}>
            <img
              alt="3"
              className="w-100 project-desc-image"
              src={FEATURES[selectedIndex].img}
            />
          </Pane>
          <Text>{FEATURES[selectedIndex].description}</Text>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default AMSidesheet;
