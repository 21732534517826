import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Lottie from "react-lottie";
import "./HomePage.css";

import aeAnim1 from "../../assets/HomePage/scrollDown.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnScrollReveal from "../../components/OnScrollReveal";
import AboutMe from "../AboutMe/AboutMe";
import Experience from "../Experience/Experience";
import Skills from "../Skills/Skills";
import Projects from "../Projects/Projects";
import { Pane } from "evergreen-ui";

//Adding Icons to Lib
library.add(fab);

const HomePage = () => {
  //for ae animations
  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const background1 = <div className="position-fixed color-first"></div>;
  const background2 = (
    <div className="position-fixed fade-out-color-first"></div>
  );

  const ChangeBg = (props) => {
    const progress = props.progress;
    if (progress < 0.22) return background1;
    // else if (progress < 0.65) return background2;
    else return background2;
  };

  return (
    <Controller>
      <Scene duration={"400%"} triggerHook={0.5}>
        {(progress) => {
          return (
            <div className="overflow-x-hidden">
              <ChangeBg progress={progress}></ChangeBg>
              <div className="container homepage-scroll-section-light">
                <div className="myLanding m-0 row">
                  <div className="landing-intro">
                    {/* <h1 className="animation fadeIn f-header">Sharan Patel</h1> */}
                    <h2 className="animation fadeInTop f-subtitle">Hello</h2>
                    <Pane
                      display="flex"
                      // justifyContent="center"
                      className="wrapper"
                      marginBottom={4}
                    >
                      <h1 className="f-header-light">I am</h1>
                      <div class="typing-demo">
                        <h1 className="f-header">Sharan Patel</h1>
                      </div>
                    </Pane>
                    <h2 className="animation fadeInBottom f-subtitle">
                      Software Engineer | Student
                    </h2>
                    <OnScrollReveal y={-100} duration={2} repeat={false}>
                      <Pane width={20} marginTop={16}>
                        <Lottie
                          options={{
                            ...defaultAnimationOptions,
                            ...{ animationData: aeAnim1 },
                          }}
                          height={64}
                          width={24}
                        />
                      </Pane>
                    </OnScrollReveal>
                  </div>
                </div>
              </div>
              <div id="about">
                <OnScrollReveal y={36} duration={1} repeat={false}>
                  <AboutMe />
                </OnScrollReveal>
              </div>
              <div id="experience">
                <OnScrollReveal y={36} duration={1} repeat={false}>
                  <Experience />
                </OnScrollReveal>
              </div>
              <div id="projects">
                <OnScrollReveal y={36} duration={1} repeat={false}>
                  <Projects />
                </OnScrollReveal>
              </div>
              <div id="skills" className="pb-5">
                <OnScrollReveal y={36} duration={1} repeat={false}>
                  <Skills />
                </OnScrollReveal>
              </div>
              <div className="landing-contact text-center">
                <h3 className="f-subtitle padding-t-70">Like my work?</h3>
                <h2 className="f-title mt-4">Let's get in touch.</h2>
                <a
                  href="mailto:sharan.patel@uwaterloo.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="mail-button mt-4">
                    sharan.patel@uwaterloo.ca
                  </button>
                </a>
                <div className="row justify-content-center mt-4 pb-4">
                  <div className="col-md-2 col-4 row justify-content-between ">
                    <a
                      href="https://github.com/SharanPatel"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "github"]}
                        size="md"
                        className="contact-icons"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sharan-patel/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "linkedin-in"]}
                        size="md"
                        className="contact-icons"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Scene>
    </Controller>
  );
};

export default HomePage;
