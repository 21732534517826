import React, { useState } from "react";
import "../HomePage/HomePage.css";
import "react-vertical-timeline-component/style.min.css";
import cu from "../../assets/Projects/coinup.png";
import am from "../../assets/Projects/academiamatch.png";
import div from "../../assets/Projects/divaluate.png";
import rm from "../../assets/Projects/runmapper.png";
import sf from "../../assets/Projects/selfolio.png";
import sfLarge from "../../assets/Projects/selfolio-large.png";
import OnScrollReveal from "../../components/OnScrollReveal";
import AMSidesheet from "./AMSidesheet";
import RMSidesheet from "./RMSidesheet";
import { toaster } from "evergreen-ui";

const Projects = () => {
  const [showAMSidesheet, setShowAMSidesheet] = useState(false);
  const [showRMSidesheet, setShowRMSidesheet] = useState(false);

  return (
    <div className="container homepage-scroll-section-dark mt-n5 mt-md-0">
      <div className="text-center">
        <h3 className="section-message">
          ...and I also enjoy building meaningful products
        </h3>
        <h2 className="section-title">Projects</h2>
      </div>
      <div className="row justify-content-between">
        <OnScrollReveal y={36} duration={1} repeat={false}>
          <div className="col-12 d-md-block d-none">
            <a
              href="https://selfolio.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sfLarge} alt="0a" className="w-100 project-image"></img>
            </a>
          </div>
          <div className="col-12 d-md-none d-block">
            <a
              href="https://selfolio.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sf} alt="0b" className="w-100 project-image"></img>
            </a>
          </div>
        </OnScrollReveal>
      </div>
      <div className="row justify-content-between mt-md-4 mt-3">
        <OnScrollReveal
          css={"col-lg-6 col-md-6 col-sm-12"}
          y={36}
          duration={1}
          repeat={false}
        >
          <a
            href="https://coinupapp.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={cu} alt="1" className="w-100 project-image"></img>
          </a>
        </OnScrollReveal>
        <OnScrollReveal
          css={"col-lg-6 col-md-6 col-sm-12 mt-md-0 mt-3"}
          y={36}
          duration={1}
          repeat={false}
        >
          <img
            onClick={() =>
              toaster.notify(
                "🛠️ Divaluate is getting an upgrade! Please check back later."
              )
            }
            src={div}
            alt="2"
            className="w-100 project-image"
          ></img>
        </OnScrollReveal>
      </div>

      <div className="row justify-content-between mt-md-4 mt-3">
        <OnScrollReveal
          css={"col-lg-6 col-md-6 col-sm-12"}
          y={36}
          duration={1}
          repeat={false}
        >
          <img
            onClick={() => setShowRMSidesheet(true)}
            src={rm}
            alt="3"
            className="w-100 project-image"
          ></img>
        </OnScrollReveal>
        <OnScrollReveal
          css={"col-lg-6 col-md-6 col-sm-12 mt-md-0 mt-3"}
          y={36}
          duration={1}
          repeat={false}
        >
          <img
            onClick={() => setShowAMSidesheet(true)}
            src={am}
            alt="4"
            className="w-100 project-image"
          ></img>
        </OnScrollReveal>
      </div>
      <AMSidesheet isShown={showAMSidesheet} setIsShown={setShowAMSidesheet} />
      <RMSidesheet isShown={showRMSidesheet} setIsShown={setShowRMSidesheet} />
    </div>
  );
};

export default Projects;
