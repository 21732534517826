import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import "./css/main.css";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
    </Switch>
  );
}
