import { Pane, Pill, Badge } from "evergreen-ui";
import React from "react";
import "../HomePage/HomePage.css";

const Tab = ({ experience }) => {
  return (
    <div>
      <Pane display="flex">
        <Pane flex={1} display="flex" className="experience-tab-company">
          {experience.company}
        </Pane>
        <Pane className="experience-tab-location">{experience.location}</Pane>
      </Pane>
      <div className="experience-tab-position">{experience.title}</div>
      <Badge color="blue">Team: {experience.team}</Badge>
      {experience.description.length > 0 && (
        <div className="experience-tab-desc">{experience.description}</div>
      )}
      {experience.stack.length > 0 && (
        <Pane>
          {experience.stack.map((skill, index) => (
            <Pill className="experience-tab-skill-pill" key={index}>
              {skill}
            </Pill>
          ))}
        </Pane>
      )}
    </div>
  );
};

export default Tab;
