import React from "react";
import "../HomePage/HomePage.css";
import "react-vertical-timeline-component/style.min.css";

const data = {
  languages: [
    {
      name: "TypeScript",
      class: "devicon-typescript-plain",
    },
    {
      name: "JavaScript",
      class: "devicon-javascript-plain",
    },
    {
      name: "Python",
      class: "devicon-python-plain",
    },
    {
      name: "Go",
      class: "devicon-go-original-wordmark",
    },
    {
      name: "Ruby",
      class: "devicon-ruby-plain",
    },
    {
      name: "C++",
      class: "devicon-cplusplus-plain",
    },
    {
      name: "C",
      class: "devicon-c-plain",
    },
    {
      name: "Java",
      class: "devicon-java-plain",
    },
    {
      name: "Rust",
      class: "devicon-rust-plain",
    },
  ],
  tools: [
    {
      name: "AWS",
      class: "devicon-amazonwebservices-original",
    },
    {
      name: "GCP",
      class: "devicon-googlecloud-plain",
    },
    {
      name: "Firebase",
      class: "devicon-firebase-plain",
    },
    {
      name: "Docker",
      class: "devicon-docker-plain",
    },
    {
      name: "NodeJS",
      class: "devicon-nodejs-plain",
    },
    {
      name: "Express",
      class: "devicon-express-original",
    },
    {
      name: "Django",
      class: "devicon-django-plain",
    },
    {
      name: "Rails",
      class: "devicon-rails-plain",
    },
    {
      name: "React",
      class: "devicon-react-original",
    },
  ],
  databases: [
    {
      name: "PostgreSQL",
      class: "devicon-postgresql-plain",
    },
    {
      name: "MySQL",
      class: "devicon-mysql-plain",
    },
    {
      name: "MongoDB",
      class: "devicon-mongodb-plain",
    },
  ],
};

const Skills = () => {
  var languages = data.languages.map((skills, i) => {
    return (
      <li className=" list-inline-item skills-tile-blue" key={i}>
        <i className={skills.class} style={{ fontSize: "240%" }} />
        <div className="text-center skill-label">{skills.name}</div>
      </li>
    );
  });

  var tools = [...data.tools, ...data.databases].map((skills, i) => {
    return (
      <li className=" list-inline-item skills-tile-red" key={i}>
        <i className={skills.class} style={{ fontSize: "240%" }} />
        <div className="text-center skill-label">{skills.name}</div>
      </li>
    );
  });

  return (
    <div className="container homepage-scroll-section-dark mt-n5 mt-md-0">
      <div className="text-center">
        <h3 className="section-message">
          New tech? Not a problem. Here are some I'm already familiar with
        </h3>
        <h2 className="section-title">Skills</h2>
      </div>
      <div className="col-md-12 text-center mb-1">
        <ul className="list-inline">{languages}</ul>
      </div>
      <div className="col-md-12 text-center">
        <ul className="list-inline">{tools}</ul>
      </div>
    </div>
  );
};

export default Skills;
