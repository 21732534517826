import {
  Heading,
  Pane,
  SideSheet,
  Tablist,
  Tab,
  Position,
  Badge,
  Text,
} from "evergreen-ui";
import React, { useState, useEffect, useMemo } from "react";

import trackImg from "../../assets/Projects/track.png";
import feedImg from "../../assets/Projects/feed.png";
import authImg from "../../assets/Projects/auth.png";

const RMSidesheet = ({ isShown, setIsShown }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [loadedImages, setLoadedImages] = useState(false);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const pictures = useMemo(() => [trackImg, feedImg, authImg], []);

  useEffect(() => {
    if (isShown && !loadedImages) {
      console.log("in here");
      for (const picture of pictures) {
        const img = new Image();
        img.src = picture;
      }
      setLoadedImages(true);
    }
  }, [isShown, loadedImages, pictures]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const STACK = [
    "JavaScript",
    "React Native",
    "NodeJS",
    "Cloud Firestore",
    "Firebase",
  ];

  const FEATURES = [
    {
      title: "Run Tracking",
      img: trackImg,
      description:
        "Realtime tracking of the run route, distance, time and pace.",
    },
    {
      title: "Run Feed",
      img: feedImg,
      description:
        "Record and view all of your past workout information to track your progress.",
    },
    {
      title: "Authenication",
      img: authImg,
      description: "Secure signup and login for all users.",
    },
  ];
  return (
    <SideSheet
      position={isMobile ? Position.BOTTOM : Position.RIGHT}
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      containerProps={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
      }}
    >
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16} borderBottom="muted">
          <Heading marginBottom={4} size={600}>
            Here's some of my work @RunMapper
          </Heading>
          {STACK.map((item, index) => (
            <Badge marginRight={4} key={index} color="blue">
              {item}
            </Badge>
          ))}
        </Pane>
        <Pane display="flex" padding={8}>
          <Tablist>
            {FEATURES.map((feature, index) => (
              <Tab
                key={feature.title}
                isSelected={selectedIndex === index}
                onSelect={() => setSelectedIndex(index)}
              >
                {feature.title}
              </Tab>
            ))}
          </Tablist>
        </Pane>
      </Pane>
      <Pane flex={1} background="tint1">
        <Pane textAlign="center" padding={16}>
          <Pane marginBottom={8}>
            <img
              alt="3"
              className="w-100 project-desc-image"
              src={FEATURES[selectedIndex].img}
            />
          </Pane>
          <Text>{FEATURES[selectedIndex].description}</Text>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default RMSidesheet;
